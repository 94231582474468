<template>
  <div :class="needLtr && 'ltrSection'">
    <v-text-field
        :loader-height="0"
        dir="rtl"
        :dense="dense"
        :outlined="outlined"
        :prepend-inner-icon="appendIcon"
        color="inherit"
        :append-outer-icon="append_outer_icon"
        :rules="rules"
        :id="idInput"
        :label="lable"
        :placeholder="placeholder"
        :type="type"
        v-model="model[modelkey]"

    >
      <template v-slot:append-outer>
        <div :id="idInput+'_append_outer'" @click="appendOuterIcon" style="cursor:pointer">
          <v-icon>{{ append_outer_icon }}</v-icon>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    tab_index_number: Number,
    lable: String,
    idInput: String,
    modelkey: String,
    model: Object,
    dense: Boolean,
    outlined: Boolean,
    needLtr: Boolean,
    append_outer_icon: String,
    appendIcon: {
      required: false
    },
    rules: {
      required: false,
    },
    placeholder: {
      required: false,
    },
    prepend_inner_icon: {
      required: false,
    },
    type: String
  },
  methods: {
    prependIconEmit() {
      this.$emit('appendIconEmit')
    },
    appendOuterIcon() {
      this.$emit('appendOuterIcon', this.model)
    }
  }
}
</script>

<style>
.v-text-field--outlined fieldset {
  border-color: #707070;
}

.ltrSection .v-text-field__slot input {
  direction: ltr !important;
}
</style>